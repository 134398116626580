import { grid } from "../../grid/grid";

export const styles = {
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        flexDirection: 'column',
        overflow: 'hidden',
        userSelect: 'none',
        cursor: 'none'
    },
    container_xl: {
        margin: `0 ${grid.xl.margin + grid.xl.grid}vw`
    },
    container_lg: {
        margin: `0 ${grid.lg.margin + grid.lg.grid}vw`,
    },
    container_md: {
        margin: `0 ${grid.md.margin}vw`
    },
    container_sm: {
        margin: `0 0`
    },
    whitespaceTop_sm: {
        width: "100vw",
        height: "90px"
    },
    whitespaceTop_md: {
        width: "100vw",
        height: "90px"
    },
    whitespaceTop_lg: {
        width: "100vw",
        height: "115px"
    },
    whitespaceTop_xl: {
        width: "100vw",
        height: "115px"
    },
    whitespaceBottom_sm: {
        width: "100vw",
        height: "65px"
    },
    whitespaceBottom_md: {
        width: "100vw",
        height: "180px"
    },
    whitespaceBottom_lg: {
        width: "100vw",
        height: "290px"
    },
    whitespaceBottom_xl: {
        width: "100vw",
        height: "290px"
    },
    mainImage: {
        maxHeight: "2000px",
        maxWidth: "100%",
        height: "auto"
    },
    mainImageHolder: {
        display: "inline-block",
        maxWidth: "100%",
        maxHeight: "2000px",
    },
    mainImageHolder_sm: {
        marginTop: "25px",
    },
    mainImageHolder_md: {
        marginLeft: grid.md.margin,
        marginRight: grid.md.margin,
        marginTop: "25px",
    },
    mainImageHolder_lg: {
        marginLeft: grid.lg.margin,
        marginRight: grid.lg.margin,
        marginTop: "40px",
    },
    mainImageHolder_xl: {
        marginLeft: grid.xl.margin,
        marginRight: grid.xl.margin,
        marginTop: "40px",
    },
    text: {
        fontSize: "16px",
        fontFamily: "'Nunito Sans', PingFang SC, sans-serif",
        color: "#2c2c2c",
        lineHeight: "24px",
        textAlign: "left",
        whiteSpace: "pre-wrap",
        userSelect: "none"
    },
    textCn: {
        fontFamily: "PingFang SC, 'Nunito Sans', sans-serif",
    },
    text_sm: {
        fontSize: "16px",
        margin: `0 ${grid.sm.margin}vw`
    },
    textSmMdSeparator:{
        width: "100%",
        height: "30px"
    },
    text_md: {
        fontSize: "16px",
        margin: `0 ${grid.md.margin + grid.md.grid + grid.md.gutter}vw`
    },
    text_lg: {
        margin: `0 ${grid.lg.margin + (grid.lg.grid * 2) + (grid.lg.gutter * 2)}vw`
    },
    text_xl: {
        margin: `0 ${grid.xl.margin + (grid.xl.grid * 2) + (grid.xl.gutter * 2)}vw`
    },
    title: {
        fontSize: '44px',
        lineHeight: '50px'
    },
    titleCn: {
        fontFamily: "PingFang SC, 'Nunito Sans', sans-serif",
        fontSize: "40px",
        lineHeight: "50px"
    },
    title_sm: {
        margin: `0 ${grid.sm.margin}vw`,
        marginTop: '10px',
        fontSize: '20px',
        lineHeight: '24px'
    },
    title_md: {
        margin: `0 ${grid.md.gutter + grid.md.grid}vw`,
        marginTop: '10px',
        fontSize: '30px',
        lineHeight: '36px'
    },
    title_lg: {
        margin: `0 ${grid.lg.margin + grid.lg.grid + grid.lg.gutter}vw`
    },
    title_xl: {
        margin: `0 ${grid.xl.margin + grid.xl.grid + grid.xl.gutter}vw`
    },
    descriptionHolder_sm: {
        display: "flex",
        flexDirection: "column",
        width: `calc(100% - ${grid.sm.margin * 2}vw)`,
        margin: `25px ${grid.sm.margin}vw`,
        justifyContent: "center"
    },
    descriptionHolder_md: {
        display: "flex",
        flexDirection: "column",
        width: `calc(100% - ${grid.md.margin * 2}vw)`,
        margin: `60px ${grid.md.margin}vw`,
        justifyContent: "center"
    },
    descriptionHolder_lg: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        margin: "100px 0",
        justifyContent: "center"
    },
    descriptionHolder_xl: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        margin: "100px 0",
        justifyContent: "center"
    },
    descriptionContent_sm: {
        margin: 0,
        width: `${grid.sm.grid * 4 + grid.sm.gutter * 2}vw`
    },
    descriptionContent_md: {
        margin: 0,
        width: `${grid.md.grid * 6   + grid.md.gutter * 5}vw`
    },
    descriptionContent_lg: {
        margin: `0 ${grid.lg.gutter / 2}vw`,
        width: `${grid.lg.grid * 4 + grid.lg.gutter * 2}vw`
    },
    descriptionContent_xl: {
        margin: `0 ${grid.xl.gutter / 2}vw`,
        width: `${grid.xl.grid * 4 + grid.xl.gutter * 2}vw`
    },
    momentsHolder: {
    },
    momentsHolder_sm: {
        width: `calc(100% - ${grid.sm.margin * 2}vw)`,
        margin: `${grid.sm.margin}vw`
    },
    momentsHolder_md: {
        width: '100%'
    },
    momentsHolder_lg: {
        width: '100%'
    },
    momentsHolder_xl: {
        width: '100%'
    },
    momentsHolder_empty: {
        backgroundColor: '#fff'
    },
    bgHolder: {
        position: 'absolute',
        zIndex: -999,
        width: '100vw',
        maxWidth: '100%',
        height: 'fit-content',
        maxHeight: '80vh',
        overflow: "hidden"
    },
    bgHolder_xl: {
        marginTop: '5%',
    },
    bgHolder_lg: {
        marginTop: '5%',
    },
    bgHolder_md: {
        marginTop: '5%',
    },
    bgHolder_sm: {
        marginTop: '5%',
    },
    bg: {
        width: '100%',
        height: 'auto',
        cursor: 'none'
    }
};