import { grid } from '../../grid/grid';

export const styles = {
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        userSelect: 'none',
        cursor: 'none'
    },
    container_xl: {
        margin: `${grid.xl.margin}vw 0`
    },
    container_lg: {
        margin: `${grid.lg.margin}vw`,
    },
    container_md: {
        margin: `${grid.md.margin}vw 0`
    },
    container_sm: {
        margin: `${grid.sm.margin}vw 0`
    },
    grid: {
        width: '100%',
        display: 'flex',
    },
    grid_xl: {
        marginTop: '-150px'
    },
    grid_lg: {
        marginTop: '-150px'
    },
    grid_md: {
        marginTop: '-50px'
    },
    grid_sm: {
        marginTop: '-50px'
    },
    whitespace_sm: {
        width: '100vw',
        height: '90px'
    },
    whitespace_md: {
        width: '100vw',
        height: '90px'
    },
    whitespace_lg: {
        width: '100vw',
        height: '115px'
    },
    whitespace_xl: {
        width: '100vw',
        height: '115px'
    },
};