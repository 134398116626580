import React from "react";

/**
 * Animated Cursor
 * Replaces the native cursor with a custom animated cursor.
 */
export default function AnimatedCursor({
  color = "120, 90, 90",
  alpha = 0.4,
  size = 20,
  scale = 3,
}) {
  const cursorRef = React.useRef(null);
  const [isVisible, setIsVisible] = React.useState(false);
  const [isActive, setIsActive] = React.useState(false);
  const [isActiveClickable, setIsActiveClickable] = React.useState(false);

  function useEventListener(eventName, handler, element = document) {
    const savedHandler = React.useRef(null);

    React.useEffect(() => {
      savedHandler.current = handler;
      return () => {
        savedHandler.current = null;
      };
    });

    React.useEffect(() => {
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;
      if (savedHandler.current === null) return;
      const eventListener = (event) => savedHandler.current(event);

      element.addEventListener(eventName, eventListener);

      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    }, [eventName, element]);
  }

  const onMouseMove = React.useCallback(({ clientX, clientY }) => {
    cursorRef.current.style.top = clientY - size / 2 + "px";
    cursorRef.current.style.left = clientX - size / 2 + "px";
    // activates the cursot if its not visible
    if (!isVisible) {
      setIsVisible(true);
    }
  }, []);

  const onMouseDown = React.useCallback(
    () => cursorRef.current !== null && setIsActive(true),
    []
  );
  const onMouseUp = React.useCallback(
    () => cursorRef.current !== null && setIsActive(false),
    []
  );
  const onMouseEnter = React.useCallback(
    () => cursorRef.current !== null && setIsVisible(true),
    []
  );
  const onMouseLeave = React.useCallback(
    () => cursorRef.current !== null && setIsVisible(false),
    []
  );

  useEventListener("mousemove", onMouseMove, document);
  useEventListener("mousedown", onMouseDown, document);
  useEventListener("mouseup", onMouseUp, document);
  useEventListener("mouseenter", onMouseEnter, document);
  useEventListener("mouseleave", onMouseLeave, document);

  React.useEffect(() => {
    if (isActive) {
      cursorRef.current.style.transform = `scale(${scale})`;
    } else {
      cursorRef.current.style.transform = "scale(1)";
    }
  }, [scale, isActive]);

  React.useEffect(() => {
    if (cursorRef.current === null) return;
    if (isActiveClickable) {
      cursorRef.current.style.transform = `scale(${scale * 1.3})`;
    }
  }, [scale, isActiveClickable]);

  React.useEffect(() => {
    if (isVisible) {
      cursorRef.current.style.opacity = 1;
    } else {
      cursorRef.current.style.opacity = 0;
    }
  }, [isVisible]);

  React.useEffect(() => {
    const clickables = document.querySelectorAll(
      'a, input[type="submit"], input[type="image"], label[for], select, button, .link'
    );
    clickables.forEach((el) => {
      el.style.cursor = "none";

      el.addEventListener("mouseover", () => {
        cursorRef.current !== null && setIsActive(true);
      });
      el.addEventListener("click", () => {
        cursorRef.current !== null && setIsActive(true);
        cursorRef.current !== null && setIsActiveClickable(false);
      });
      el.addEventListener("mousedown", () => {
        cursorRef.current !== null && setIsActiveClickable(true);
      });
      el.addEventListener("mouseup", () => {
        cursorRef.current !== null && setIsActive(true);
      });
      el.addEventListener("mouseout", () => {
        cursorRef.current !== null && setIsActive(false);
        cursorRef.current !== null && setIsActiveClickable(false);
      });
    });

    return () => {
      clickables.forEach((el) => {
        el.removeEventListener("mouseover", () => {
          cursorRef.current !== null && setIsActive(true);
        });
        el.removeEventListener("click", () => {
          cursorRef.current !== null && setIsActive(true);
          cursorRef.current !== null && setIsActiveClickable(false);
        });
        el.removeEventListener("mousedown", () => {
          cursorRef.current !== null && setIsActiveClickable(true);
        });
        el.removeEventListener("mouseup", () => {
          cursorRef.current !== null && setIsActive(true);
        });
        el.removeEventListener("mouseout", () => {
          cursorRef.current !== null && setIsActive(false);
          cursorRef.current !== null && setIsActiveClickable(false);
        });
      });
    };
  });

  const styles = {
    cursor: {
      zIndex: 999,
      position: "fixed",
      width: size,
      height: size,
      opacity: 0,
      pointerEvents: "none",
      transition: "opacity 0.15s ease-in-out, transform 0.15s ease-in-out",
      borderRadius: "50%",
      backgroundColor: `rgba(${color}, ${alpha})`,
      // border: `1px solid rgba(${color}, ${alpha})`
    },
    activeCursor: {
      zIndex: 999,
      position: "fixed",
      width: size,
      height: size,
      opacity: 0,
      pointerEvents: "none",
      transition: "opacity 0.15s ease-in-out, transform 0.15s ease",
      borderRadius: "50%",
      backgroundColor: `rgba(${color}, ${alpha})`,
      // border: `1px solid rgba(${color}, ${alpha})`
    },
  };

  return (
    <>
      <div
        ref={cursorRef}
        style={isActive ? styles.activeCursor : styles.cursor}
        className='animated'
      />
    </>
  );
}
