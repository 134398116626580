import {grid} from "../../grid/grid";

export const styles = {
    container_xl: {
        position: 'relative',
        padding: `80px ${grid.xl.gutter / 2}vw`,

    },
    container_lg: {
        position: 'relative',
        padding: `80px ${grid.lg.gutter / 2}vw`,

    },
    container_md: {
        position: 'relative',
        padding: `60px ${grid.md.gutter / 2}vw`,

    },
    container_sm: {
        position: 'relative',
        padding: `30px ${grid.sm.gutter / 2}vw`,
    },
    image: {
        height: 'auto'
    },
    image_xl: {
        width: `${grid.xl.grid * 3 + grid.xl.gutter * 3}vw`
    },
    image_lg: {
        width: `${grid.lg.grid * 3 + grid.lg.gutter * 3}vw`,
    },
    image_md: {
        width: `${grid.md.grid * 4 + grid.md.gutter * 4}vw`
    },
    image_sm: {
        width: `${grid.sm.grid * 4 + grid.sm.gutter * 3}vw`
    },
    link: {cursor: 'default', textDecoration: 'none'}
};