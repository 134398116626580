import {grid} from '../../grid/grid';

export const styles = {
    font: {
        lineHeight: '30px',
        fontSize: '20px',
        fontWeight: 400,
        fontFamily: '\'Nunito Sans\', PingFang SC, sans-serif',
        color: '#2c2c2c',
        // backgroundColor: '#fff',
        textAlign: 'center',
        whiteSpace: 'pre-wrap',
        userSelect: 'none'
    },
    text_sm: {
        fontSize: '16px',
        lineHeight: '24px',
        margin: `0 ` + grid.sm.margin + 'vw',
        minHeight: 'calc(100vh - 95px)'
    },
    text_md: {
        fontSize: '16px',
        lineHeight: '24px',
        margin: `0 ` + grid.md.margin
            + grid.md.gutter
            + grid.md.grid + 'vw',
    },
    text_lg: {
        margin: `0 ` + grid.lg.margin
            + grid.lg.gutter
            + grid.lg.grid + 'vw',
    },
    text_xl: {
        margin: `0 ` + grid.xl.margin
            + grid.xl.gutter
            + grid.xl.grid + 'vw',
    },
    aboutTextLink: {
        textDecoration: 'none',
        cursor: 'default',
        color: '#2c2c2c',
        '&:hover': {
            textDecoration: 'none',
            color: '#624FFF',
            borderColor: '#624FFF',
            cursor: 'pointer',
        },
    },
    aboutTextLink_sm_md: {
        textDecoration: 'none',
        color: '#624FFF',
        borderColor: '#624FFF',
        cursor: 'pointer',
    },
    description: {
        marginBottom: '40px'
    },
    whitespace_sm: {
        marginTop: '95px'
    },
    whitespace_md: {
        marginTop: '155px'
    },
    whitespace_lg: {
        marginTop: '180px'
    },
    whitespace_xl: {
        marginTop: '180px'
    },
    background: {
        position: 'absolute',
        maxWidth: '100vw',
        maxHeight: 'calc(100vh - 80px)',
        top: 0,
        left: 0,
        zIndex: -100
    },
    holder: {
        userSelect: 'none',
        overflow: 'hidden',
        cursor: 'none'
    },
    holder_sm: {
        margin: `0 0`,
    },
    holder_md: {
        margin: `60px 0`,
    },
    holder_lg: {
        margin: `80px ${grid.lg.margin}vw`,
    },
    holder_xl: {
        margin: `80px ${grid.xl.margin}vw`,
    },
    imageHolder: {
        position: 'fixed',
        zIndex: -999,
        width: '100vw',
        maxWidth: '100vw',
        height: 'fit-content',
        maxHeight: '80vh',
        overflow: 'hidden',
        cursor: 'none'
    },
    imageHolder_xl: {
        top: 0,
        margin: `100px ${grid.xl.margin}vw`,
        marginTop: '380px',
        width: `calc(100vw - 2 * ${grid.xl.margin}vw)`
    },
    imageHolder_lg: {
        top: 0,
        margin: `100px ${grid.lg.margin}vw`,
        marginTop: '380px',
        width: `calc(100vw - 2 * ${grid.lg.margin}vw)`
    },
    imageHolder_md: {
        bottom: 0,
        marginBottom: `10px`
    },
    imageHolder_sm: {
        bottom: 0,
        margin: `15px 0`
    },
    image: {
        width: '100%',
        height: 'auto'
    }
};