export const styles = {
    container: {
        width: '100%',
        height: '100%',
        maxWidth: '960px',
        maxHeight: '672px',
        margin: '5vw',
        border: 'solid 1px #624fff'
    },
    errorMessage: {
        whiteSpace: "pre-wrap",
    },
    refreshButton: {

    }
};
