import React from "react";

import styled from 'styled-components'

import { MenuItemHolder } from "./MenuItemHolder";
import { device } from "../../device/Device";

export function scrollToTop(location, currentLocation) {
    if (location === currentLocation) {
        window.scrollTo(0, 0)
    }
}

const StyledMenu = styled.div`
    position: fixed;
    width: 100vw;
    top: 0;
    z-index: 1;
    background-color: #fff;
    cursor: none;
    
    height: 50px;
    padding-top: 30px;
    
    @media screen and ${device.md} {
        height: 40px;
        padding-top: 20px;
    }
`;

const Menu = () =>  {
    return (
      <StyledMenu>
        <MenuItemHolder />
      </StyledMenu>
    );
}

export { Menu }