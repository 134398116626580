import React from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import { styles } from "./GridImageTitleStyles";
import clsx from "clsx";


const useStyles = makeStyles(styles);

export default function GridImageTitle(props) {
    const { title, titleCn, desc, displayType, titleStyleOverride } = props;
    const classes = useStyles();
    return <div className={clsx(classes.container, classes[`container_${displayType}`])} style={titleStyleOverride}>
      <div className={classes.spacer12px} />
      <div className={classes.font}>
        {title}
      </div>
      <div className={classes.font}>
        {titleCn}
      </div>
      <div className={classes.spacer4px} />
      <div className={clsx(classes.font, classes.shortDescFont)}>
        {desc}
      </div>
    </div>;
}