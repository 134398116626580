import React from 'react';
import axios from "axios";
import './App.css';
import AboutMe from "./Components/Text/AboutMe";
import ImageGrid from "./Components/imageGrid/ImageGrid";
import {Redirect, Switch, BrowserRouter} from "react-router-dom";
import {TrackedRoute} from "./Components/TrackedRoute";
import ReactGA from 'react-ga';
import useMediaQueries from "./hooks/useMediaQueries";
import ArtDetail from "./Components/artworkDetail/ArtDetail";
import {MenuProvider} from "./MenuContext";
import Error from "./Components/error/Error";
import {Menu} from "./Components/menu/Menu";

function App() {
  const [siteData, setSiteData] = React.useState({"list": [], "grid": [], about: "", baseImageUrl: ""});
  const displayType = useMediaQueries(
      {'(min-width: 1921px)': 'xl', '(min-width: 1024px)': 'lg', '(min-width: 768px)': 'md', '(min-width: 0px)': 'sm'},
      'sm');

  const [isStaticMenu, setStaticMenu] = React.useState();
  const [useMenuOffset, setMenuOffset] = React.useState();


  function calcImageSize() {
    switch (displayType) {
      case 'xl':
        return 1920;
      case 'lg':
        return 1440;
      case 'md':
        return 768;
      default:
        return 500;
    }
  }

  const imageSize = calcImageSize();

  React.useEffect(() => {
    const fetchData = async () => {
        const response = await axios.get(process.env.REACT_APP_BASE_URL + '/site');
        setSiteData(response.data.data);
      }
    fetchData();
  }, []);

  React.useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_TOKEN)
  }, []);


  return (
    <BrowserRouter>
      <MenuProvider value={{isStaticMenu, setStaticMenu, useMenuOffset, setMenuOffset}}>
        <div className='App' style={{maxWidth: "100vw"}}>
          <Menu />
          <Switch>
            <TrackedRoute exact path='/' render={() => <Redirect to='/gallery' />} />
            <TrackedRoute exact path='/gallery' render={() => <ImageGrid baseUrl={siteData.baseImageUrl} images={siteData.list} displayType={displayType} />} />
            <TrackedRoute exact path='/about' render={() => <AboutMe about={siteData.about} displayType={displayType} />} />
            <TrackedRoute exact path='/detail/:artId' render={() => <ArtDetail displayType={displayType} imageSize={imageSize} />} />
            <TrackedRoute exact path='/error' render={() => <Error />} />
            <TrackedRoute render={() => <Error />} />
          </Switch>
        </div>
      </MenuProvider>
    </BrowserRouter>
  );
}

export default App;
