import React from "react";

/**
 *
 * @param queries
 * @param defaultValue
 *
 * Usage
 * useMedia(
 *   {'(min-width: 1921px)': 'xl','(min-width: 1024px)': 'lg', '(min-width: 768px)': 'md', '(min-width: 0px)': 'sm'},
 *   'sm');
 * @returns {*}
 */
function useMediaQueries(queries, defaultValue) {
    // Array containing a media query list for each query
    const mediaQueryLists = Object.keys(queries).map(q => window.matchMedia(q));

    // Function that gets value based on matching media query
    const getValue = () => {
        // Get index of first media query that matches
        const index = mediaQueryLists.find(mql => mql.matches);
        // Return related value or defaultValue if none
        return queries[index?.media] || defaultValue;
    };

    // State and setter for matched value
    const [value, setValue] = React.useState(getValue);

    React.useEffect(
        () => {
            // Event listener callback
            // Note: By defining getValue outside of useEffect we ensure that it has ...
            // ... current values of hook args (as this hook callback is created once on mount).
            const handler = () => setValue(getValue);
            // Set a listener for each media query with above handler as callback.
            mediaQueryLists.forEach(mql => mql.addListener(handler));
            // Remove listeners on cleanup
            return () => mediaQueryLists.forEach(mql => mql.removeListener(handler));
        },
        [] // Empty array ensures effect is only run on mount and unmount
    );

    return value;
}

export default useMediaQueries;