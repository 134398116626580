import React from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import {styles} from "./ErrorStyles";
import {Link} from "react-router-dom";


const useStyles = makeStyles(styles);

export default function Error() {
    const classes = useStyles();
    const message = '404 / 503 / Or Something Wrong\n' +
        '          Take a break / Or…'
    return <div>
      <div className={classes.container}>
        <div className={classes.errorMessage}>{message}</div>
        <Link to='/'><div className={classes.refreshButton}>VISIT HOME</div></Link>
      </div>
    </div>
}
