import React from "react";
import { Link } from "react-router-dom";

import styled from 'styled-components'

import { device } from "../../device/Device";

type MenuItemProps = {
    linkTo: string,
    onClick: Function,
    text: string
}

const StyledMenuText = styled.p`
    z-index: 5;
    position: relative;
    color: #2c2c2c;
    font-family: 'Nunito Sans', PingFang SC, sans-serif;
    font-size: 16px;
    font-weight: 800;
    line-height: 24px;
    text-decoration: none;
    letter-spacing: 2.285714px;
    float: right;
    clear: right;
    margin-top: 0px;
    border-style: solid;
    border-width: 0px 0px 3px 0px;
    border-color: transparent;
    padding: 2px;
    user-select: none;
    
    cursor: pointer;    
    margin-bottom: 20px;
    
    @media screen and ${device.md} {
        &:hover: {
            text-decoration: none;
            color: $#624FFF;
            border-color: #624FFF;
        }
        cursor: none;
        margin-bottom: 2px;
    }
`;

const MenuItem = ({ linkTo, onClick, text }: MenuItemProps) =>
  <Link to={linkTo}>
    <StyledMenuText onClick={onClick}>{text}<span /></StyledMenuText>
  </Link>

export { MenuItem };
