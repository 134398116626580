import React from "react";

import clsx from "clsx";
import makeStyles from "@material-ui/styles/makeStyles";
import { styles } from "./MomentsStyles";
const useStyles = makeStyles(styles);

export default function Moments(props) {
    const {images, baseUrl, displayType, imageSize } = props;
    const classes = useStyles();

    function buildMomentsRow(idx) {
        return <div className={clsx(classes.imageRow, classes.imageRowSingleImage)}>
          <div className={classes.imageHolder}>
            <div>
              <img className={clsx(classes.mainImage, classes[`mainImage_${displayType}`])} src={`${baseUrl}${images[idx].url}?width=${imageSize}`} alt='this is how I did it' />
            </div>
          </div>
        </div>
    }
    function buildMomentsTwinRow(left, right) {
        return <div className={classes.imageRow}>
          <div>
            <span className={clsx(classes.imageHelper, classes[`twinAlign_${images[left].alignment}`])} />
            <div className={clsx(classes.imageHolder, classes[`twinAlign_${images[left].alignment}`])}>
              <img className={clsx(classes.twinImage, classes.twinImageLeft, classes[`twinImageLeft_${displayType}`])} src={`${baseUrl}${images[left].url}?width=${imageSize}`} alt='this is how I did it' />
            </div> 
          </div>
          <div>
            <span className={clsx(classes.imageHelper, classes[`twinAlign_${images[right].alignment}`])} />
            <div className={clsx(classes.imageHolder, classes[`twinAlign_${images[right].alignment}`])}>
              <img className={clsx(classes.twinImage, classes.twinImageRight, classes[`twinImageRight_${displayType}`])} src={`${baseUrl}${images[right].url}?width=${imageSize}`} alt='this is how I did it' />
            </div>
          </div>
        </div>
    }

    if (images === undefined || images === null || images.length < 1) return null;
    switch(images.length) {
        case 1:
            return <div>
              {buildMomentsRow(0)}
            </div>
        case 2:
            return <div>
              {buildMomentsTwinRow(0, 1)}
            </div>
        case 3:
            return <div>
              {buildMomentsRow(0)}
              <div className={classes[`lineSpacer_${displayType}`]} />
              {buildMomentsTwinRow(1, 2)}
            </div>
        case 4:
            return <div>
              {buildMomentsTwinRow(0, 1)}
              <div className={classes[`lineSpacer_${displayType}`]} />
              {buildMomentsTwinRow(2, 3)}
            </div>
        default:
            return null;
    }
}