import React, { useCallback, useEffect } from "react";
import Masonry from "react-masonry-css";
import GridTile from "./GridTile";
import { gsap } from "gsap";

import clsx from "clsx";
import makeStyles from "@material-ui/styles/makeStyles";
import { styles } from "./ImageGridStyles";
import AnimatedCursor from "../AnimatedCursor";
const useStyles = makeStyles(styles);

export default function ImageGrid(props) {
  const { baseUrl, images, displayType } = props;
  const classes = useStyles();

  let colCount;
  switch (displayType) {
    case "xl":
      colCount = 3;
      break;
    case "lg":
      colCount = 3;
      break;
    case "md":
      colCount = 2;
      break;
    case "sm":
      colCount = 1;
      break;
    default:
      colCount = 2;
  }

  const updateTileStyles = useCallback(($tile) => {
    const tileRect = $tile.getBoundingClientRect();
    const scrollTop = parseInt(tileRect.bottom) || 400;
    const scrollBottom =
      parseInt(window.innerHeight - tileRect.top) || 400;

    const fadeOutThreshold =
      parseInt(tileRect.height * 0.3) | 350;
    const fadeInThreshold =
      parseInt(tileRect.height * 1) | 250;
    const fadeOutHeightAdjustment = 80;
    const baseOpacity = 1;
    const fadeOutOpacityIntensity = 2;
    const fadeInHeightAdjustment = 120;

    // let style = {};
    let x = 0;
    let y = 0;
    let opacity = 100;

    if (
      (scrollTop > fadeOutThreshold && scrollBottom > fadeInThreshold) ||
      // if the element doesnt fully fit the screen and triggers both transitions at once, no animation is applied
      (scrollTop < fadeOutThreshold && scrollBottom < fadeInThreshold)
    ) {
      x = 0;
      y = 0;
      opacity = 100;
      // style = {
      // transform: `translate(0px, 0px)`,
      // opacity: "100%",
      // };
    } else if (scrollTop < fadeOutThreshold) {
      opacity =
        (baseOpacity -
          fadeOutOpacityIntensity * (1 - scrollTop / fadeOutThreshold)) *
        100;
      x = 0;
      y = -(fadeOutHeightAdjustment * (1 - scrollTop / fadeOutThreshold));
      // style = {
      //   opacity: `${
      //     (baseOpacity -
      //       fadeOutOpacityIntensity * (1 - scrollTop / fadeOutThreshold)) *
      //     100
      //   }%`,
      //   transform: `translate(0px, -${
      //     fadeOutHeightAdjustment * (1 - scrollTop / fadeOutThreshold)
      //   }px)`,
      // };
    }
    // the scroll bottom has to be <0 to avoid flicked on the edge case
    else if (scrollBottom <= fadeInThreshold && scrollBottom > -40) {
      const topOffset =
        fadeInHeightAdjustment *
        Math.pow(1 - scrollBottom / fadeInThreshold, 3);

      x = 0;
      y = topOffset;

      // style = {
      // transform: `translate(0px, ${topOffset}px)`,
      // };
      // this has to be 0 to avoid flicker
    } else if (scrollBottom < 0) {
      x = 0;
      y = fadeInHeightAdjustment;

      // style = {
      //   transform: `translate(0px, ${fadeInHeightAdjustment}px)`,
      // };
    }

    gsap.set($tile, { x, y, opacity: opacity / 100 });
  }, []);

  const handleScrollEvent = useCallback(() => {
    const $tiles = Array.from(document.querySelectorAll(".grid-image-tile"));
    for (const $tile of $tiles) {
      updateTileStyles($tile);
    }
  }, [updateTileStyles]);

  useEffect(() => {
    document.addEventListener("scroll", handleScrollEvent, false);
    return () => {
      document.removeEventListener("scroll", handleScrollEvent);
    };
  }, []);

  return (
    <>
      {(displayType === "xl" || displayType === "lg") && (
        <AnimatedCursor
          color='98, 79, 255'
          alpha={0.4}
          defaultSize={50}
          scale={5}
        />
      )}
      <div
        className={clsx(classes.container, classes["container_" + displayType])}
      >
        <div className={classes["whitespace_" + displayType]} />
        <Masonry
          breakpointCols={colCount}
          className={clsx(classes.grid, classes[`grid_${displayType}`])}
          columnClassName='my-masonry-grid_column'
        >
          {images.map((image) => (
            <GridTile
              key={image.id}
              baseUrl={baseUrl}
              image={image}
              displayType={displayType}
            />
          ))}
        </Masonry>
      </div>
    </>
  );
}
