export const grid = {
    xl: {
        margin: 3.75,
        gutter: 2.5,
        grid: 3.4375
    },
    lg: {
        margin: 4.6875,
        gutter: 3.125,
        grid: 4.6875
    },
    md: {
        margin: 6.251,
        gutter: 4.1214,
        grid: 7.25
    },
    sm: {
        margin: 7.5,
        gutter: 5,
        grid: 17.5
    }
}