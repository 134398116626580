import {grid} from "../../grid/grid";

export const styles = {
    font: {
        fontSize: '16px',
        fontFamily: '\'Nunito Sans\', PingFang SC, sans-serif',
        fontWeight: 400,
        color: '#2c2c2c',
        lineHeight: 1,
        textAlign: 'left',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        height: '18px',
        marginBottom: '4px',
        userSelect: 'none',
    },
    shortDescFont: {
        color: '#8C8C8C'
    },
    spacer12px: {
        width: '100%',
        height: '12px'
    },
    spacer4px: {
        width: '100%',
        height: '8px',
    },
    container: {
        margin: '0 auto'
    },
    container_xl: {
        width: `${grid.xl.grid * 3 + grid.xl.gutter * 3}vw`
    },
    container_lg: {
        width: `${grid.lg.grid * 3 + grid.lg.gutter * 3}vw`,
    },
    container_md: {
        width: `${grid.md.grid * 4 + grid.md.gutter * 4}vw`
    },
    container_sm: {
        width: `${grid.sm.grid * 4 + grid.sm.gutter * 3}vw`
    },
};