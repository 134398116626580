import React from "react";
import { useLocation } from "react-router-dom";

import styled from 'styled-components'

import { scrollToTop } from "./Menu";
import { MenuItem } from "./MenuItem";

import { grid } from '../../grid/grid';
import { device } from "../../device/Device";


const StyledMenuItem = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: none;
    
    margin: 0 ${grid.xl.margin}vw;

    @media screen and ${device.lg} {
         margin: 0 ${grid.lg.margin}vw;
    }
    @media screen and ${device.md} {
         margin: 0 ${grid.md.margin}vw;
    }
    @media screen and ${device.sm} {
         margin: 0 ${grid.sm.margin}vw;
    }
`;

const MenuItemHolder = () => {
    const location = useLocation();

    return (
      <StyledMenuItem>
        <MenuItem
          linkTo='/gallery'
          onClick={() => scrollToTop("/gallery", location.pathname)}
          text='MI WANG'
        />
        <MenuItem
          linkTo='/about'
          onClick={() => scrollToTop("/about", location.pathname)}
          text='CONTACT'
        />
      </StyledMenuItem>
    )
}

export { MenuItemHolder };