import makeStyles from "@material-ui/styles/makeStyles";
import clsx from "clsx";
import React, { useCallback, useMemo, useRef } from "react";
import * as ReactGA from "react-ga";
import ProgressiveImage from "react-progressive-image";
import { Link } from "react-router-dom";
import { grid } from "../../grid/grid";
import GridImageTitle from "../Text/GridImageTitle";
import { styles } from "./GridTileStyles";

const useStyles = makeStyles(styles);

function gridToDetails(id) {
  ReactGA.event({
    category: "grid-to-details",
    action: id.toString(),
    label: id.toString(),
  });
}

function nonEmpty(string, defaultString) {
  return string.length > 0 && string !== "null" ? string : defaultString;
}

export default function GridTile(props) {
  const classes = useStyles();

  let tileRef = useRef(null);

  const { baseUrl, image, displayType } = props;

  const imagePositioning = useMemo(() => {
    if (displayType === "sm") {
      const baseImageSize = grid.sm.grid * 4 + grid.sm.gutter * 3;
      const imageSettings = image.positioning.split(" ");

      return {
        width: `${
          (baseImageSize *
            (Number(imageSettings[0]) -
              Number(imageSettings[2]) -
              Number(imageSettings[4]))) /
          100
        }vw`,
        margin: `0 ${imageSettings[2]}vw 0 ${imageSettings[4]}vw 0`,
      };
    } else {
      return {};
    }
  }, [displayType]);

  const titleStyleOverride = useMemo(() => {
    return displayType === "sm" ? { width: imagePositioning.width } : {};
  }, [displayType, imagePositioning]);

  const handleTitleClick = useCallback(() => {
    gridToDetails(image.id);
  }, [image.id]);

  return (
    <div
      key={image.order}
      className={clsx(
        classes[`container_${displayType}`],
        "grid-image-tile",
        "animated"
      )}
      ref={tileRef}
    >
      <Link
        to={`detail/${image.id}`}
        className={classes.link}
        onClick={handleTitleClick}
      >
        <ProgressiveImage
          src={`${baseUrl}${image.url}?width=650`}
          placeholder={`${baseUrl}${image.url}?width=120`}
        >
          {(src) => (
            <img
              src={`${baseUrl}${image.url}?width=650`}
              className={clsx(classes.image, classes[`image_${displayType}`])}
              style={imagePositioning}
              alt={image.title}
            />
          )}
        </ProgressiveImage>
        <GridImageTitle
          title={nonEmpty(image.title, "untitled")}
          titleCn={nonEmpty(image.titleCn, "为您名")}
          desc={nonEmpty(image.description, "")}
          displayType={displayType}
          titleStyleOverride={titleStyleOverride}
        />
      </Link>
    </div>
  );
}
