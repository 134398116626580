const size = {
    xl: `1919px`,
    lg: `1439px`,
    md: `767px`,
}

const device = {
    xl: `(min-width: ${size.xl})`,
    lg: `(max-width: ${size.xl})`,
    md: `(max-width: ${size.lg})`,
    sm: `(max-width: ${size.md})`,
};

export { device };