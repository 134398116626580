import React from "react";
import clsx from "clsx";
import makeStyles from "@material-ui/styles/makeStyles";
import { styles } from "./AboutMeStyles";
import * as ReactGA from "react-ga";
import AnimatedCursor from "../AnimatedCursor";

const useStyles = makeStyles(styles);

export default function AboutMe(props) {
    const {about, displayType} = props;
    const classes = useStyles();
    let responsiveTextStyle = "text_" + displayType;
     return (
       <>
         {(displayType === 'xl' || displayType === 'lg') && <AnimatedCursor color='98, 79, 255' alpha={0.4} defaultSize={50} scale={5} />}
         <div className={clsx(classes.holder, classes[`holder_${displayType}`])}>
           <div className={classes[`whitespace_${displayType}`]} />
           <div className={clsx(classes.font, classes[responsiveTextStyle])}>
             {about?.content}
             <span>
               Follow on
             </span>
             <br />
             <br />
             <span>
               <a
                 href='https://dribbble.com/DNAheartMi' target='_blank' rel='noreferrer noopener'
                 className={clsx(classes.aboutTextLink, ['sm', 'md'].includes(displayType) && classes.aboutTextLink_sm_md)}
                 onClick={() => {
                    ReactGA.event({
                       category: "socialClick",
                       action: "behance",
                       label: "behance"
                    });
                 }}
               >
                 Dribbble<span />
               </a>
               {' / '}
               <a
                 href='https://www.behance.net/LIIM-Mi' target='_blank' rel='noreferrer noopener'
                 className={clsx(classes.aboutTextLink, ['sm', 'md'].includes(displayType) && classes.aboutTextLink_sm_md)}
                 onClick={() => {
                    ReactGA.event({
                      category: "socialClick",
                      action: "behance",
                      label: "behance"
                    });
                 }}
               >
                 Behance<span />
               </a>
               {' / '}
               <a
                 href='https://www.instagram.com/midontunderstand/' target='_blank' rel='noreferrer noopener'
                 className={clsx(classes.aboutTextLink, ['sm', 'md'].includes(displayType) && classes.aboutTextLink_sm_md)}
                 onClick={() => {
                    ReactGA.event({
                      category: "socialClick",
                      action: "instagram",
                      label: "instagram"
                    });
                 }}
               >
                 Instagram<span />
               </a>
             </span>
           </div>
         </div>
         <div className={clsx(classes.imageHolder, classes[`imageHolder_${displayType}`])}>
           <img
             className={classes.image}
             src={`${process.env.PUBLIC_URL}/bg/contact_bg_${displayType}.svg`} alt='aboutImage'
           />
         </div>
       </>
    )
}