import React from "react";
import {
    useParams
} from "react-router-dom"
import Moments from "./Moments";

import clsx from "clsx";
import makeStyles from "@material-ui/styles/makeStyles";
import { styles } from "./ArtDetailStyles";
import axios from "axios";
import ProgressiveImage from "react-progressive-image";
import AnimatedCursor from "../AnimatedCursor";
const useStyles = makeStyles(styles);

export default function ArtDetail(props) {
    const { artId } = useParams();
    const { displayType, imageSize } = props;
    const [details, setDetails] = React.useState({title: '', titleCn: '', baseImageUrl: '', imageUrl: '',
    description: '', descriptionCn: '', detailImages: [], background: ''})
    const classes = useStyles();

    React.useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/details/${artId}`);
            setDetails(response.data.data);
        }
        fetchData();
    }, [artId]);


    return <>
      {(displayType === 'xl' || displayType === 'lg') && <AnimatedCursor color='98, 79, 255' alpha={0.4} defaultSize={50} scale={5} />}
      <div className={clsx(classes.container, classes[`container_${displayType}`])}>
        <div className={classes[`whitespaceTop_${displayType}`]} />
        <div className={clsx(classes.text, classes.title, classes[`title_${displayType}`])}>{details.title}</div>
        <div className={clsx(classes.text, classes.titleCn, classes[`title_${displayType}`])}>{details.titleCn}</div>
        <div className={clsx(classes.mainImageHolder, classes[`mainImageHolder_${displayType}`])}>
          <ProgressiveImage src={`${details.baseImageUrl}${details.imageUrl}`} placeholder={`${details.baseImageUrl}${details.imageUrl}?width=200`}>
            {src => <img className={classes.mainImage} src={src} alt={details.title} />}
          </ProgressiveImage>
        </div>
        <div className={clsx(classes[`descriptionHolder_${displayType}`], details.detailImages.length === 0 && classes.momentsHolder_empty)}>
          <div className={clsx(classes.text, classes[`text_${displayType}`], classes[`descriptionContent_${displayType}`])}>{details.descCn}</div>
          {["sm", "md"].includes(displayType) && <div className={classes.textSmMdSeparator} />}
          <div className={clsx(classes.text, classes.textCn, classes[`text_${displayType}`], classes[`descriptionContent_${displayType}`])}>{details.desc}</div>
        </div>
        <div className={clsx(classes.momentsHolder, classes[`momentsHolder_${displayType}`])}>
          <Moments images={details.detailImages} baseUrl={details.baseImageUrl} displayType={displayType} imageSize={imageSize} />
        </div>
      </div>
      <div className={clsx(classes.bgHolder, classes[`bgHolder_${displayType}`])}>
        <img
          className={classes.bg}
          src={`${process.env.PUBLIC_URL}/bg/details_bg_${displayType}.svg`} alt='detailsBg'
        />
      </div>
    </>;
}
