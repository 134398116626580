import { grid } from "../../grid/grid";

export const styles = {
    container: {
        backgroundColor: "red",
    },
    imageRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        maxWidth: "100%",
        height: "auto"
    },
    imageRowSingleImage:{
        justifyContent: 'center'
    },
    imageHolder: {
        display: "inline-block",
        maxWidth:   "100%",
    },
    mainImage: {
        height: "auto"
    },
    mainImage_sm: {
        maxWidth: `100%`,
    },
    mainImage_md: {
        maxWidth: `calc(100% - ${grid.md.grid * 2}vw)`,
    },
    mainImage_lg: {
        maxWidth: `calc(100% - ${(grid.lg.gutter+ grid.lg.grid) * 4}vw)`,
    },
    mainImage_xl: {
        maxWidth: `calc(100% - ${(grid.xl.gutter+ grid.xl.grid) * 4}vw)`,
    },
    twinImage: {
        // maxWidth: `calc(100% - ${grid.lg.gutter / 2}vw)`,
        height: "auto"
    },
    twinImageLeft: {
        float: 'left'
    },
    twinImageRight: {
        float: 'right'
    },
    twinImageLeft_xl: {
        maxWidth: `calc(100% - ${grid.xl.gutter}vw)`,
    },
    twinImageLeft_lg: {
        maxWidth: `calc(100% - ${grid.lg.gutter}vw)`,
    },
    twinImageLeft_md: {
        maxWidth: `calc(100% - ${grid.md.gutter / 2}vw)`,
    },
    twinImageLeft_sm: {
        maxWidth: `calc(100% - ${grid.sm.gutter / 2}vw)`,
    },
    twinImageRight_xl: {
        maxWidth: `calc(100% - ${grid.xl.grid}vw)`,
    },
    twinImageRight_lg: {
        maxWidth: `calc(100% - ${grid.lg.grid}vw)`,
    },
    twinImageRight_md: {
        maxWidth: `calc(100% - ${grid.md.gutter / 2}vw)`,
    },
    twinImageRight_sm: {
        maxWidth: `calc(100% - ${grid.sm.gutter / 2}vw)`,
    },
    imageHelper: {
        display: 'inline-block',
        height: '100%',
    },
    twinAlign_top:{
        verticalAlign: 'top'
    },
    twinAlign_center:{
        verticalAlign: 'middle'
    },
    twinAlign_bottom:{
        verticalAlign: 'bottom'
    },
    lineSpacer_sm: {
        width: "100vw",
        height: "25px"
    },
    lineSpacer_md: {
        width: "100vw",
        height: "60px"
    },
    lineSpacer_lg: {
        width: "100vw",
        height: "160px"
    },
    lineSpacer_xl: {
        width: "100vw",
        height: "160px"
    }
}